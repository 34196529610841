//image type for aws service
export const profile_image_type = 1;
export const floor_image_type = 2;
export const admin_image_type = 3;


export class RolePermissions {
    static roles = 0;
    static admins = 1;
    static manageEmployee = 2;
    static manageDepartment = 3;
    static manageTeam = 4;
    static manageGateway = 5;
    static manageLocation = 33;
    static manageVisitorCardId = 6;
    static manageEmployeeCardId = 7;
    static sendNotification = 8;
    static repository = 9;
    static mailIds = 10;
    static todaysAttendance = 11;
    static emergencyRollCall = 12;
    static inOut = 13;
    static todaysAbsentees = 14;
    static nowInOffice = 15;
    static outOfOffice = 16;
    static crossOver = 17;
    static notification = 18;
    static nonReturnCards = 19;
    static nonReturnEmail = 20;
    static manageNonThalesVisitors = 21;
    static nonThalesVisitorsReport = 22;
    static nonThalesVisitorsNowInOffice = 23;
    static nonThalesVisitorsOutOfOffice = 24;
    static nonThalesVisitorsCrossOver = 25;
    static nonThalesVisitorsPreRegistered = 26;
    static manageThalesVisitors = 27;
    static thalesVisitorsReport = 28;
    static thalesVisitorsNowInOffice = 29;
    static thalesVisitorsOutOfOffice = 30;
    static thalesVisitorsCrossOver = 31;
    static thalesVisitorsPreRegistered = 32;
}

export const configurationPermissionIndices = [
    RolePermissions.manageEmployee,
    RolePermissions.manageDepartment,
    RolePermissions.manageTeam,
    RolePermissions.manageVisitorCardId,
    RolePermissions.manageEmployeeCardId,
    RolePermissions.repository,
    RolePermissions.sendNotification,
    RolePermissions.mailIds,
    RolePermissions.manageGateway,
    RolePermissions.manageLocation
]

export const reportsPermissionIndices = [
    RolePermissions.todaysAttendance,
    RolePermissions.todaysAbsentees,
    RolePermissions.nowInOffice,
    RolePermissions.outOfOffice,
    RolePermissions.inOut,
    RolePermissions.notification,
    RolePermissions.emergencyRollCall,
    RolePermissions.crossOver,
    RolePermissions.nonReturnCards
]

export const nonThalesVisitorsPermissionIndices = [
    RolePermissions.manageNonThalesVisitors,
    RolePermissions.nonThalesVisitorsNowInOffice,
    RolePermissions.nonThalesVisitorsOutOfOffice,
    RolePermissions.nonThalesVisitorsCrossOver,
    RolePermissions.nonThalesVisitorsReport,
    RolePermissions.nonThalesVisitorsPreRegistered
]

export const thalesVisitorsPermissionIndices = [
    RolePermissions.manageThalesVisitors,
    RolePermissions.thalesVisitorsNowInOffice,
    RolePermissions.thalesVisitorsOutOfOffice,
    RolePermissions.thalesVisitorsCrossOver,
    RolePermissions.thalesVisitorsReport,
    RolePermissions.thalesVisitorsPreRegistered
]