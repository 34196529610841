import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { useProfileDetails } from "hooks/profile/profile";
import './App.css';

import Image from "helpers/Image";
//layouts
import AuthLayout from './layouts/authLayout';
import AppLayout from './layouts/appLayout';
import Profile from 'views/app/profile';
import { RolePermissions, nonThalesVisitorsPermissionIndices, thalesVisitorsPermissionIndices } from 'helpers/constants/constants';
import globalRouter from './globalRouter.ts';

// Auth Routes
const SignIn = lazy(() => import("./views/auth/SignIn"));

//App Routes
const Dashboard = lazy(() => import("./views/app/dashboard"));

const TodayAttendance = lazy(() => import("./views/app/reports/attendance"));
const FirstInLastOut = lazy(() => import("./views/app/reports/inOut"));
const TodayAbsentees = lazy(() => import("./views/app/reports/absentees"));
const EmergencyRollcall = lazy(() => import("./views/app/reports/emergencyRollcall"));
const NowInOfficeReport = lazy(() => import("./views/app/reports/nowInOffice"));
const EmployeeOutOfOffice = lazy(() => import("./views/app/reports/outOfOffice"));
const CrossOver = lazy(() => import("./views/app/reports/crossOver"));
const Notification = lazy(() => import("./views/app/reports/notification"));
const NonReturnCard = lazy(() => import("./views/app/reports/nonReturnId"));

const Department = lazy(() => import("./views/app/configuration/department"));
const Employee = lazy(() => import("./views/app/configuration/employee"));
const Team = lazy(() => import("./views/app/configuration/team"));
const Repository = lazy(() => import("./views/app/configuration/repository"));
const VisitorCardId = lazy(() => import("./views/app/configuration/visitorCardId"));
const EmployeeCardId = lazy(() => import("./views/app/configuration/employeeCardId"));
const Gateway = lazy(() => import("./views/app/configuration/gateway"));
const ConfigNotification = lazy(() => import("./views/app/configuration/notification"));
const Location = lazy(() => import("./views/app/configuration/location"));
const MailIds = lazy(() => import("./views/app/configuration/mailIds"));


const ThalesVisitors = lazy(() => import("./views/app/visitors/thales-visitors"));
const ManageThalesVisitors = lazy(() => import("./views/app/visitors/thales-visitors/manage-thales-visitors"));
const ThalesVisitorReports = lazy(() => import("./views/app/visitors/thales-visitors/thales-visitor-reports"));
const NowInOffice = lazy(() => import("./views/app/visitors/thales-visitors/now-in-office"));
const OutOfOffice = lazy(() => import("./views/app/visitors/thales-visitors/out-of-office"));
const VisitorCrossOver = lazy(() => import("./views/app/visitors/thales-visitors/cross-over"));
const PreRegistered = lazy(() => import("./views/app/visitors/thales-visitors/pre-registered"));
const MyThalesPreRegistered = lazy(() => import("./views/app/visitors/thales-visitors/my-thales-pre-registered"));
const TodayPreRegistered = lazy(() => import("./views/app/visitors/thales-visitors/today-pre-registered"));

const NonThalesVisitors = lazy(() => import("./views/app/visitors/non-thales-visitors"));
const ManageNonThalesVisitors = lazy(() => import("./views/app/visitors/non-thales-visitors/manage-non-thales-visitors"));
const NonThalesVisitorReports = lazy(() => import("./views/app/visitors/non-thales-visitors/non-thales-visitor-reports"));
const NonThalesNowInOffice = lazy(() => import("./views/app/visitors/non-thales-visitors/now-in-office"));
const NonThalesOutOfOffice = lazy(() => import("./views/app/visitors/non-thales-visitors/out-of-office"));
const NonThalesCrossOver = lazy(() => import("./views/app/visitors/non-thales-visitors/cross-over"));
const NonThalesPreRegistered = lazy(() => import("./views/app/visitors/non-thales-visitors/pre-registered"));
const MyNonThalesPreRegistered = lazy(() => import("./views/app/visitors/non-thales-visitors/my-non-thales-pre-registered"));

const Admin = lazy(() => import("./views/app/profile/admin"));
const Roles = lazy(() => import("./views/app/profile/roles"));


function App() {
  const navigate = useNavigate();
  globalRouter.navigate = navigate;
  const { data: profileData, refetch: initialRefetch } = useProfileDetails();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (profileData) {
      setData(profileData?.data?.resDto)
    }
  }, [profileData])

  const displayItem = (index) => {
    if (data[index]?.actionAddReqDto?.isAdd || data[index]?.actionAddReqDto?.isDelete || data[index]?.actionAddReqDto?.isEdit) {
      return true;
    }
    if (data[index]?.actionAddReqDto?.isView) {
      return true;
    }
    return false;
  }

  const configurationNavigation = () => {
    if (displayItem(RolePermissions.manageEmployee)) {
      return "/configuration/employee";
    }
    if (displayItem(RolePermissions.manageDepartment)) {
      return "/configuration/department";
    }
    if (displayItem(RolePermissions.manageTeam)) {
      return "/configuration/manage-team";
    }
    if (displayItem(RolePermissions.repository)) {
      return "/configuration/repository";
    }
    if (displayItem(RolePermissions.manageVisitorCardId)) {
      return "/configuration/visitor-card-id";
    }
    if (displayItem(RolePermissions.manageEmployeeCardId)) {
      return "/configuration/employee-card-id";
    }
    if (displayItem(RolePermissions.manageGateway)) {
      return "/configuration/gateway";
    }
    if (displayItem(RolePermissions.sendNotification)) {
      return "/configuration/notification";
    }
    if (displayItem(RolePermissions.mailIds)) {
      return "/configuration/mail-ids";
    }
  }

  const reportNavigation = () => {
    if (displayItem(RolePermissions.todaysAttendance)) {
      return "/reports/attendance";
    }
    if (displayItem(RolePermissions.todaysAbsentees)) {
      return "/reports/absentees";
    }
    if (displayItem(RolePermissions.inOut)) {
      return "/reports/in-out";
    }
    if (displayItem(RolePermissions.nowInOffice)) {
      return "/reports/now-in-office";
    }
    if (displayItem(RolePermissions.outOfOffice)) {
      return "/reports/out-of-office";
    }
    if (displayItem(RolePermissions.crossOver)) {
      return "/reports/cross-over";
    }
    if (displayItem(RolePermissions.emergencyRollCall)) {
      return "/reports/emergency-roll-call";
    }
    if (displayItem(RolePermissions.notification)) {
      return "/reports/notification";
    }
    if (displayItem(RolePermissions.nonReturnCards)) {
      return "/reports/non-return-id";
    }
  }

  const showSideBar = (indexArray) => {
    return indexArray.some((item) => {
      const perms = data[item]?.actionAddReqDto;
      return perms?.isAdd === true || perms?.isEdit === true || perms?.isDelete === true || perms?.isView === true;
    });
  }

  const visitorNavigation = () => {
    if (showSideBar(thalesVisitorsPermissionIndices)) {
      return "/visitors/thales-visitors";
    }
    if (showSideBar(nonThalesVisitorsPermissionIndices)) {
      return "/visitors/non-thales-visitors";
    }
  }


  return (
    <Suspense fallback={
    <div className='loader'>
     <Image name="loading.gif" className="main_loading" />
    </div>
  }>
      <Routes>

        <Route element={<AuthLayout />}>
          <Route exact path="/" element={<SignIn />} />
          <Route path="/login" element={<SignIn />} />
        </Route>

        <Route element={<AppLayout profileData={profileData} initialRefetch={initialRefetch} />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/reports" element={<Navigate to={reportNavigation()} />} />
          <Route path="/reports/attendance" element={<TodayAttendance hide={data} />} />
          <Route path="/reports/absentees" element={<TodayAbsentees hide={data} />} />
          <Route path="/reports/in-out" element={<FirstInLastOut hide={data} />} />
          <Route path="/reports/now-in-office" element={<NowInOfficeReport hide={data} />} />
          <Route path="/reports/out-of-office" element={<EmployeeOutOfOffice hide={data} />} />
          <Route path="/reports/cross-over" element={<CrossOver hide={data} />} />
          <Route path="/reports/emergency-roll-call" element={<EmergencyRollcall hide={data} />} />
          <Route path="/reports/notification" element={<Notification hide={data} />} />
          <Route path="/reports/non-return-id" element={<NonReturnCard hide={data} />} />

          <Route path="/configuration" element={<Navigate to={configurationNavigation()} />} />
          <Route path="/configuration/department" element={<Department hide={data} />} />
          <Route path="/configuration/employee" element={<Employee hide={data} />} />
          <Route path="/configuration/manage-team" element={<Team hide={data} />} />
          <Route path="/configuration/repository" element={<Repository hide={data} />} />
          <Route path="/configuration/visitor-card-id" element={<VisitorCardId hide={data} />} />
          <Route path="/configuration/employee-card-id" element={<EmployeeCardId hide={data} />} />
          <Route path="/configuration/gateway" element={<Gateway hide={data} />} />
          <Route path="/configuration/location" element={<Location hide={data} />} />
          <Route path="/configuration/notification" element={<ConfigNotification hide={data} />} />
          <Route path="/configuration/mail-ids" element={<MailIds hide={data} />} />

          <Route path="/visitors" element={<Navigate to={visitorNavigation()} />} />
          <Route path="/visitors/thales-visitors" element={<ThalesVisitors hide={data} />} />
          <Route path="/visitors/thales-visitors/:id" element={<ThalesVisitors hide={data} />} />

          <Route path="/visitors/thales-visitors/manage-thales-visitors" element={<ManageThalesVisitors hide={data} />} />
          <Route path="/visitors/thales-visitors/manage-thales-visitors/:id" element={<ManageThalesVisitors hide={data} />} />
          <Route path="/visitors/thales-visitors/thales-visitor-reports" element={<ThalesVisitorReports hide={data} />} />
          <Route path="/visitors/thales-visitors/thales-visitor-reports/:id" element={<ThalesVisitorReports hide={data} />} />
          <Route path="/visitors/thales-visitors/now-in-office" element={<NowInOffice hide={data} />} />
          <Route path="/visitors/thales-visitors/now-in-office/:id" element={<NowInOffice hide={data} />} />
          <Route path="/visitors/thales-visitors/out-of-office" element={<OutOfOffice hide={data} />} />
          <Route path="/visitors/thales-visitors/out-of-office/:id" element={<OutOfOffice hide={data} />} />
          <Route path="/visitors/thales-visitors/cross-over" element={<VisitorCrossOver hide={data} />} />
          <Route path="/visitors/thales-visitors/cross-over/:id" element={<VisitorCrossOver hide={data} />} />
          <Route path="/visitors/thales-visitors/pre-registered" element={<PreRegistered hide={data} />} />
          <Route path="/visitors/thales-visitors/pre-registered/:id" element={<PreRegistered hide={data} />} />
          <Route path="/visitors/thales-visitors/my-thales-pre-registered" element={<MyThalesPreRegistered hide={data} />} />
          <Route path="/visitors/thales-visitors/my-thales-pre-registered/:id" element={<MyThalesPreRegistered hide={data} />} />
          <Route path="/visitors/thales-visitors/today-pre-registered" element={<TodayPreRegistered hide={data} />} />
          <Route path="/visitors/thales-visitors/today-pre-registered/:id" element={<TodayPreRegistered hide={data} />} />

          <Route path="/visitors" element={<Navigate to="/visitors/non-thales-visitors" />} />
          <Route path="/visitors/non-thales-visitors" element={<NonThalesVisitors hide={data} />} />
          <Route path="/visitors/non-thales-visitors/:id" element={<NonThalesVisitors hide={data} />} />

          <Route path="/visitors/non-thales-visitors/manage-non-thales-visitors" element={<ManageNonThalesVisitors hide={data} />} />
          <Route path="/visitors/non-thales-visitors/manage-non-thales-visitors/:id" element={<ManageNonThalesVisitors hide={data} />} />
          <Route path="/visitors/non-thales-visitors/non-thales-visitor-reports" element={<NonThalesVisitorReports hide={data} />} />
          <Route path="/visitors/non-thales-visitors/non-thales-visitor-reports/:id" element={<NonThalesVisitorReports hide={data} />} />
          <Route path="/visitors/non-thales-visitors/now-in-office" element={<NonThalesNowInOffice hide={data} />} />
          <Route path="/visitors/non-thales-visitors/now-in-office/:id" element={<NonThalesNowInOffice hide={data} />} />
          <Route path="/visitors/non-thales-visitors/out-of-office" element={<NonThalesOutOfOffice hide={data} />} />
          <Route path="/visitors/non-thales-visitors/out-of-office/:id" element={<NonThalesOutOfOffice hide={data} />} />
          <Route path="/visitors/non-thales-visitors/cross-over" element={<NonThalesCrossOver hide={data} />} />
          <Route path="/visitors/non-thales-visitors/cross-over/:id" element={<NonThalesCrossOver hide={data} />} />
          <Route path="/visitors/non-thales-visitors/pre-registered" element={<NonThalesPreRegistered hide={data} />} />
          <Route path="/visitors/non-thales-visitors/pre-registered/:id" element={<NonThalesPreRegistered hide={data} />} />
          <Route path="/visitors/non-thales-visitors/my-non-thales-pre-registered" element={<MyNonThalesPreRegistered hide={data} />} />
          <Route path="/visitors/non-thales-visitors/my-non-thales-pre-registered/:id" element={<MyNonThalesPreRegistered hide={data} />} />

          <Route path="/profile" element={<Profile initialRefetch={initialRefetch} profileData={profileData} />} />
          <Route path="/admin" element={<Admin hide={data} />} />
          <Route path="/roles" element={<Roles hide={data} initialRefetch={initialRefetch} />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
