import React, { Fragment } from "react";

const Image = ({ name, className, onClick = null }) => {

    const image = require(`assets/${name}`);

    return (
        <Fragment>
            <img className={className} src={image} onClick={onClick} alt="..." />
        </Fragment>
    )
};

export default Image;