import moment from "moment";

export const statusColor = (status) => {
    if (status) {
        return "active-btn";
    } else {
        return "inactive-btn";
    }
};

export const changeDateFormat = (date) => moment(date).format("YYYY-MM-DD");

export const changeMonthFormat = (date) => moment(date).format("MM");
export const changeYearFormat = (date) => moment(date).format("YYYY");

export const disableFutureDates = (current) => current && current > moment().endOf('day');


export const singaporeTime = (val, format = 'DD-MM-YYYY HH:mm:ss') => {
    if (val) {
        const utcTime = moment.utc(val, format);
        const singaporeTime = utcTime.clone().utcOffset('+08:00');
        const formattedTime = singaporeTime.format('DD-MM-YYYY HH:mm:ss');
        return formattedTime;
    }
}

export const singaporeTimeOnly = (val) => {
    if (val) {
        const utcTime = moment.utc(val, 'DD-MM-YYYY HH:mm:ss');
        const singaporeTime = utcTime.clone().utcOffset('+08:00');
        const formattedTime = singaporeTime.format('HH:mm:ss');
        return formattedTime;
    }
}

export const singaporeTimeWithoutSeconds = (val, format = 'DD-MM-YYYY HH:mm:ss') => {
    if (val) {
        const utcTime = moment.utc(val, format);
        const singaporeTime = utcTime.clone().utcOffset('+08:00');
        const formattedTime = singaporeTime.format('DD-MM-YYYY HH:mm');
        return formattedTime;
    }
}

export const isSameDate = (startDate, endDate) => {
    return moment(startDate, 'DD-MM-YYYY HH:mm:ss').isSame(moment(endDate, 'DD-MM-YYYY HH:mm:ss'), 'day');
}

export const getDateOnly = (date) => {
    return moment(date, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY");
}

export const removeSecondsFromColonSeperatedString = (value) => {
    let splitString = value.split(":");
    splitString.pop();

    let resultString = splitString.join(":");
    return resultString;
}

export const exportToJson = (dataCsv, fileName) => {
    downloadFile({
        data: dataCsv,
        fileName: fileName,
        fileType: 'text/csv',
    })
}

export const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
}

export const validatePassword = (_, value) => {
    const hasLetter = /[a-z]/.test(value);
    const hasCapitalLetter = /[A-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSymbol = /[@$!%*?&]/.test(value);
    const isValidLength = value.length >= 9 && value.length <= 20;

    if (value.trim().length === 0) {
        return Promise.resolve();
    }

    if (!hasLetter) {
        return Promise.reject('Password must contain at least one letter');
    }

    if (!hasCapitalLetter) {
        return Promise.reject('Password must contain at least one capital letter');
    }

    if (!hasNumber) {
        return Promise.reject('Password must contain at least one number');
    }

    if (!hasSymbol) {
        return Promise.reject('Password must contain at least one symbol');
    }

    if (!isValidLength) {
        return Promise.reject('Password must be between 9 and 20 characters');
    }

    return Promise.resolve();
};

export function encodeData(inputString) {
	if (typeof TextEncoder === 'function') {
		const encoder = new TextEncoder();
		const data = encoder.encode(inputString);
		const base64 = btoa(String.fromCharCode(...data));
		return base64;
	} else {
		// console.log("gloval")
	}
}

export function decodeData(base64String) {
	const base64Regex = /^[A-Za-z0-9+/]+={0,2}$/;
	if (base64Regex.test(base64String)) {
		try {
			const decodedString = atob(base64String);
			return decodedString;
		} catch (error) {
			// console.log(error)
		}
	} else {
		// console.log(base64String, "global")
	}
}

export const spaceValidation = (_, value) => {
    // Regular expression to check for spaces
    const spaceRegex = /\s/;
    
    if (spaceRegex.test(value)) {
      return Promise.reject('Spaces are not allowed');
    }
    
    return Promise.resolve();
  };
