const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;

const fetchAwsImage = async (imgPath, imageType) => {
    const data = await fetch(`${REACT_APP_BASE_API}/getFileStream?filename=${imgPath}&imageType=${imageType}`, {
        method: "get",
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("token")}`,
        }
    })
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
    return data;
}


export {
    fetchAwsImage
};