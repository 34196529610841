import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom';
import { Row, Col } from 'antd';
import AppSidebar from "../utils/app/sidebar"
import "../utils/style.css"
import { Navigate } from 'react-router-dom';
import Image from 'helpers/Image';
import globalRouter from "globalRouter.ts";

function AppLayout({ profileData, initialRefetch }) {
    const auth = localStorage.getItem("token") ? true : false;
    const [imageSrc, setImageSrc] = useState('menubar.svg');
    const [Classchange, setClasschange] = useState("thales-sidebar");
    const [Classchange2, setClasschange2] = useState("menubardiv");
    const [buttonchange, setButtonchange] = useState(true);
    useEffect(() => {
        initialRefetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (buttonchange) {
            setImageSrc('menubar.svg')
            setClasschange("thales-sidebar")
            setClasschange2("menubardiv")
        }
        else {
            setImageSrc('closeicon.svg')
            setClasschange("thales-sidebar_res")
            setClasschange2("menubardiv_hide")
        }

    }, [buttonchange]);
    const handleClick = (check) => {
        setButtonchange(!check)

    };
    const buttonclose = (e) => {
        setButtonchange(e)

    };

    const [logout, setLogout] = useState(false);

    // const timeout = 1800000; // 30 minutes in milliseconds
    const timeout = 3600000; // 1 hour in milliseconds

    useEffect(() => {
        let timer;

        const resetTimer = () => {
            clearTimeout(timer);
            timer = setTimeout(() => setLogout(true), timeout);
        };

        const events = [
            'load',
            'mousemove',
            'mousedown',
            'click',
            'scroll',
            'keypress'
        ];

        const resetEvents = () => {
            events.forEach(event => {
                window.addEventListener(event, resetTimer);
            });
        };

        resetTimer();
        resetEvents();

        return () => {
            clearTimeout(timer);
            events.forEach(event => {
                window.removeEventListener(event, resetTimer);
            });
        };
    }, [timeout]);

    useEffect(() => {
        if (logout) {
            // Perform logout actions here, e.g., redirect to login page
            localStorage.removeItem('token');
            globalRouter.navigate("/login", { state: { message: "Session Time Out! Please ReLogin." } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logout]);



    return auth ?
        <div className="thales-app-view">
            <div className={Classchange2}>
                <Image className={'menubar'} name={imageSrc} onClick={() => handleClick(buttonchange)} />
                <div className="txt-center">
                    <Image name="login_logo.svg" className="sidebar-logo" />
                </div>
            </div>

            <Row>
                {/* <div className='menu_background' onClick={() => handleClick(false)}> */}
                <Col lg={4} xl={3} xxl={3} className={Classchange}>
                    <AppSidebar profileData={profileData} buttonclose={buttonclose} />
                </Col>
                {/* </div>
                {buttonchange? <Col lg={3} className={Classchange}>
                    <AppSidebar profileData={profileData} buttonclose={buttonclose}/>
                </Col>:null
                } */}
                <Col lg={20} xl={21} xxl={21} md={24} xs={24} className="thales-body">
                    <Outlet />
                </Col>
            </Row>
        </div> : <Navigate to="/" replace={true} />
}
export default AppLayout;