import { useMutation, useQuery } from "react-query"
import * as profile from "service/profile/profile"

export const useProfileDetails = () => {
    return useQuery("profileDetails", () => profile.ProfileDetails(), {
        enabled: false,
        retry: false,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false
    })
}

export const useUpdateProfileDetails = () => {
    return useMutation((formdata) => profile.UpdateProfileDetails(formdata));
}