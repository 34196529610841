import React, { Fragment } from 'react';
import { Button, Col, Input, Row } from 'antd';
import Image from 'helpers/Image';
import { NavLink, useLocation } from 'react-router-dom';
import "./style.css";
import { RolePermissions } from 'helpers/constants/constants';

const ThalesAdminHeader = (props) => {
    const { onOpen, onSearch, loading, hide } = props;

    const location = useLocation();

    const displayItem = (index) => {
        if (hide[index]?.actionAddReqDto?.isAdd) {
            return true;
        }
        return false;
    }

    const hideAddButton = () => {
        try {
            if (location.pathname === '/profile') {
                return true;
            }
            if (location.pathname === '/admin') {
                return displayItem(RolePermissions.admins);
            }
            if (location.pathname === '/roles') {
                return displayItem(RolePermissions.roles);
            }
        } catch (error) {
        }
    }

    const getCreateActionButton = () => {
        if (location.pathname === '/profile') {
            return "Update";
        }
        if (location.pathname === '/admin') {
            return "Add User";
        }
        if (location.pathname === '/roles') {
            return "Add Role";
        }
    }

    const getActions = () => {
        if (location.pathname === '/profile') {
            onOpen();
        }
        if (location.pathname === '/admin') {
            onOpen();
        }
        if (location.pathname === '/roles') {
            onOpen();
        }
    }

    const searchInputPlaceHolder = () => {
        if (location.pathname === '/admin') {
            return "Search user";
        }
        if (location.pathname === '/roles') {
            return "Search role";
        }
    }

    return (
        <Fragment>
            <header className='profile-head'>
                <Row gutter={[10, 10]}>
                    <Col md={15}>
                        <div className='profile-header'>
                            <NavLink to="/profile"><Image name="li_users.svg" />My Details</NavLink>
                            {<NavLink to="/admin"><Image name="config-employee.svg" />Admin</NavLink>}
                            {<NavLink to="/roles" ><Image name="li_ungroup.svg" />Roles</NavLink>}
                        </div>
                    </Col>
                    <Col md={9} className='profile-header-actions'>
                        {location.pathname !== '/profile' && <Input placeholder={searchInputPlaceHolder()} onChange={onSearch} prefix={<Image name="u_search.svg" />} />}
                        {hideAddButton() && <Button onClick={getActions} loading={loading}>{getCreateActionButton()}{location.pathname !== '/profile' && <Image name="li_plus.svg" />}</Button>}
                    </Col>
                </Row>
            </header>
        </Fragment >
    )
}

export default ThalesAdminHeader

