import React, { Fragment, useEffect, useRef, useState } from 'react';
import ThalesAdminHeader from './common';
import "./common/style.css";
import { Col, Form, Row, Upload, Input, message, Tooltip } from 'antd';
import Image from 'helpers/Image';
import { validatePassword } from 'helpers/commonFunctions';
import { QuestionCircleOutlined } from '@ant-design/icons';
import PrivateImage from 'helpers/PrivateImage';
import { useUpdateProfileDetails } from 'hooks/profile/profile';
import { profile_image_type } from 'helpers/constants/constants';

const Profile = ({ initialRefetch, profileData }) => {
    const [form] = Form.useForm();
    const ref = useRef();
    const [files, setFiles] = useState(null);
    const { mutate: editProfile, isLoading } = useUpdateProfileDetails();

    const uploadProps = {
        name: "file",
        multiple: false,
        accept: "images/*",
        onChange(info) {
            const file = info.fileList[0];
            const isJpgOrPng =
                file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPng) {
                return false;
            }
            setFiles(info.fileList);
        },
        onDrop(e) {
            const file = e.dataTransfer.files[0];
            const isJpgOrPng =
                file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPng) {
                return false;
            }
            setFiles(e.dataTransfer.files);
        },
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!");
            form.setFieldsValue({ floorImage: null });
            setFiles(null);
        }
        return isJpgOrPng;
    };

    useEffect(() => {
        initialRefetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateConfirmPassword = ({ getFieldValue }) => ({
        validator(_, value) {
            if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
            }
            return Promise.reject('The two passwords do not match');
        },
    });

    const onFinish = () => {
        const formData = new FormData();
        if (files !== null) {
            formData.append("profileImage", files[0]?.originFileObj)
        }
        formData.append("firstName", form.getFieldValue("firstName")?.trim());
        formData.append("lastName", form.getFieldValue("lastName")?.trim());
        formData.append("email", form.getFieldValue("email")?.trim());
        if (form.getFieldValue("password")) {
            formData.append("password", form.getFieldValue("password"));
            formData.append("confirmPassword", form.getFieldValue("confirmPassword"));
        }
        editProfile(formData, {
            onSuccess: (item) => {
                if (item?.statusCode === 200) {
                    message.success(item?.data);
                    initialRefetch();
                } else {
                    message.error(item?.data);
                }
            }
        })
    }

    useEffect(() => {
        if (profileData) {
            form.setFieldsValue({
                "firstName": profileData?.data?.firstName,
                "lastName": profileData?.data?.lastName,
                "email": profileData?.data?.email
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileData])

    return (
        <Fragment>
            <h1>Profile</h1>
            <ThalesAdminHeader onOpen={onFinish} loading={isLoading} />
            <div className='profile-form'>
                <div className="profile-content">
                    <Form
                        form={form}
                        ref={ref}
                        name="add_user"
                        autoComplete="off"
                        layout="vertical"
                        className="thales-form">
                        <Row gutter={[16, 0]}>
                            <Col md={24} className="profile-head">
                                {files !== null ? <img
                                    src={URL.createObjectURL(files[0]?.originFileObj)}
                                    alt=".."
                                    className="profile-img"
                                /> : profileData !== null && profileData?.data?.profileImageUrl !== null ?
                                    <PrivateImage privateUrl={profileData?.data?.profileImageUrl} className="profile-img" imgType={profile_image_type} />
                                    : <Image name="place-profile.png" className="profile-img" />}
                                <Upload {...uploadProps} showUploadList={false}
                                    maxCount={1}
                                    beforeUpload={beforeUpload}>
                                    <Image name="u_image-upload.svg" className="upload-btn-img" /> Click to Upload
                                </Upload>
                            </Col>
                            <Col xl={12} md={12} xs={24}>
                                <Form.Item name="firstName" label="First Name" rules={[{ required: true, message: "Enter the name!", whitespace: true }]}>
                                    <Input maxLength={30} placeholder='Enter the first name' />
                                </Form.Item>
                            </Col>
                            <Col xl={12} md={12} xs={24}>
                                <Form.Item name="lastName" label="Last Name" rules={[{ required: true, message: "Enter the name!", whitespace: true }]}>
                                    <Input maxLength={30} placeholder='Enter the last name' />
                                </Form.Item>
                            </Col>
                            <Col xl={12} md={12} xs={24}>
                                <Form.Item label="Email" name="email" rules={[{ required: true, message: "Enter the email ID!" }]}>
                                    <Input disabled placeholder="Enter the email ID" maxLength={40}></Input>
                                </Form.Item>
                            </Col>
                            <Col xl={12} md={12} xs={24}>
                                <Form.Item label="Role">
                                    <Input disabled placeholder="Enter the role" value={profileData?.data?.roleName}></Input>
                                </Form.Item>
                            </Col>
                            <Col xl={12} md={12} xs={24}>
                                <Form.Item name="password" label={
                                    <span>
                                        Password&nbsp;
                                        <Tooltip title="At least one letter, one capital letter, one number, one symbol, 9 to 20 characters">
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </span>
                                } hasFeedback rules={[
                                    { validator: validatePassword },]}>
                                    <Input.Password maxLength={30} placeholder='Enter the password to change' autoComplete='new-password' />
                                </Form.Item>
                            </Col>
                            <Col xl={12} md={12} xs={24}>
                                <Form.Item name="confirmPassword" label="Confirm Password" dependencies={['password']}
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            required: !!getFieldValue("password"),
                                            message: "Please confirm your password!",
                                            whitespace: true,
                                        }),
                                        validateConfirmPassword,
                                    ]}
                                    hasFeedback>
                                    <Input.Password maxLength={30} placeholder='Enter password to confirm' type='password' autoComplete='new-password' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </Fragment >
    )
}

export default Profile;