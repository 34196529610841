import MultipartUploadClient from "helpers/constants/multipartUploadNetwork";
import PrivateNetworkClient from "helpers/constants/privateNetwork";

const ProfileDetails = async () => {
    if (localStorage.getItem('token') === null) {
        return null;
    }
    const { data } = await PrivateNetworkClient.get("/user/profileDetails");
    return data;
};

const UpdateProfileDetails = async (formData) => {
    const { data } = await MultipartUploadClient.put("/user/editProfile", formData);
    return data;
}

export {
    ProfileDetails,
    UpdateProfileDetails
};