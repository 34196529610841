import React, { Fragment } from 'react';
import { Row, Col, Avatar, Popover } from 'antd';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Image from 'helpers/Image';
import PrivateImage from 'helpers/PrivateImage';
import { configurationPermissionIndices, nonThalesVisitorsPermissionIndices, profile_image_type, reportsPermissionIndices, thalesVisitorsPermissionIndices } from 'helpers/constants/constants';

const Sidebar = (props) => {
    const { profileData, buttonclose } = props;
    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem('token');
        navigate("/login");
    }

    const showSideBar = (indexArray) => {
        return indexArray.some((item) => {
            const perms = profileData?.data?.resDto[item]?.actionAddReqDto;
            return perms?.isAdd === true || perms?.isEdit === true || perms?.isDelete === true || perms?.isView === true;
        });
    }

    const content = (
        <div className='pop-hover-content'>
            <div className='pop-profile' onClick={() => navigate("/profile", { state: { profileData: profileData } })}>
                <p>Profile</p>
                <Image name="profile-right-arrow.svg" />
            </div>
            <hr />
            <div className='pop-logout' onClick={logout}>
                <p>Logout</p>
            </div>
        </div>
    );
    const Buttonclose = (check) => {
        buttonclose(check)
    };
    return (
        <Fragment>

            <div className='sidebar-main' >
                <div className='reponse_close'>
                    <Image className={'menubar'} name={'closeicon.svg'} onClick={() => Buttonclose(true)} />
                    <Image name="login_logo.svg" className="sidebar-logo" />
                </div>
                <div className="txt-center">
                    <Image name="login_logo.svg" className="sidebar-logo" />
                </div>
                <div className='sidebar-links'>
                    <NavLink to="/dashboard" onClick={() => Buttonclose(true)}><Image name="dashboard.svg" />Dashboard</NavLink>
                    {showSideBar(reportsPermissionIndices) && <NavLink to="/reports" onClick={() => Buttonclose(true)}><Image name="chart-square.svg" />Reports</NavLink>}
                    {showSideBar(configurationPermissionIndices) && <NavLink to="/configuration" onClick={() => Buttonclose(true)}><Image name="settings.svg" />Configuration</NavLink>}
                    {showSideBar([...thalesVisitorsPermissionIndices, ...nonThalesVisitorsPermissionIndices]) && <NavLink to="/visitors" onClick={() => Buttonclose(true)}><Image name="arrow-up-down.svg" />Visitors</NavLink>}
                    {/* <NavLink to="/admin"><Image name="settings-admin.svg" />Admin</NavLink> */}
                </div>
                <div className="download-app">
                    <p>Applications</p>
                    <Row gutter={[16, 8]}>
                        <Col md={12}>
                            <Link to="https://drive.google.com/file/d/1ylfgBRaVwEeDPKWbY9bbi5zU18qe7MUI/view?usp=sharing" target='_blank'>ERS</Link>
                        </Col>
                        <Col md={12}>
                            <Link to="https://drive.google.com/file/d/1mKxd6PIvGiIWa47FYV8kaTbKryJLa63E/view?usp=sharing" target='_blank'>VMS</Link>
                        </Col>
                    </Row>
                </div>
                <Popover content={content}>
                    <div className='profile'>
                        <Row gutter={[16, 8]}>
                            <Col xs={6} md={6}>
                                {profileData?.data?.profileImageUrl ? <PrivateImage className="sidebar-pic" privateUrl={profileData?.data?.profileImageUrl} imgType={profile_image_type} /> : <Avatar size={35}>S</Avatar>}
                            </Col>
                            <Col xs={14} md={14}>
                                <p><b>{profileData?.data?.firstName} {profileData?.data?.lastName}</b></p>
                                <p>{profileData?.data?.roleName}</p>
                            </Col>
                            <Col xs={1} md={1}>
                                <Image className="dote" name="dote.svg" />
                            </Col>
                        </Row>
                    </div>
                </Popover>

            </div>
        </Fragment >
    )
}

export default Sidebar

