import { useQuery } from "react-query";
import * as imgApi from "service/awsImageServices/awsImageSerices";

export const useAwsImage = (imgPath, imgType) => {
    return useQuery(["AwsProfileImages", imgPath], () => imgApi.fetchAwsImage(imgPath, imgType), {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: false,
        refetchOnReconnect: true,
    });
}